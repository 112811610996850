import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import media, { BREAKPOINT_MEDIUM, BREAKPOINT_LARGE } from "styles/media";
import DealCard from "components/DealCard";
import styled from "styled-components";
import paths from "utils/paths";
import spacing from "styles/spacing";
import useCurrentWidth from "utils/useCurrentWidth";
import { SAILING_DEAL_SHAPE } from "constants/propTypes";
import Chevron from "public/icons/chevron.svg";
import Slider from "react-slick";
import { black70 } from "styles/colors";
// Import css files
import "slick-carousel/slick/slick.css";

const settings = {
  slidesToScroll: 4,
  infinite: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1430,
      settings: {
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1060,
      settings: {
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
      },
    },
  ],
};

const ArrowWrapper = styled.div`
  display: none;
  ${media.medium`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ $left }) =>
      $left &&
      `
    left: -38px;
    transform: scaleX(-1) rotate(-90deg);
    bottom: calc(55% - 4px);
    `}
    ${({ $right }) =>
      $right &&
      `
    right: -38px;
    transform: rotate(-90deg);
    bottom: calc(55% - 4px);
    `}
    cursor: pointer;
    height: 32px;
    width: 32px;
  `}
`;

const CarouselContainer = styled.div`
  width: calc(100% + ${spacing[5]});
  position: relative;
  left: -16px;
  ${media.medium`
    left: 0px;
    width: inherit;
    margin-left: ${spacing[5]};
    margin-right: ${spacing[5]};
  `}
  @media (min-width: 1430px) {
    max-width: 1288px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const CardContainer = styled.div`
  margin-bottom: 50px;
  max-width: 278px;
  ${media.medium`
    max-width: 310px;
    margin-bottom: ${spacing[6]};
  `}
  margin-right: ${spacing[4]};
`;

const renderDealCard = (sailing, cardWidth, handleOfferClick) => {
  const {
    arrival_port,
    departure_port,
    display_name,
    ship: { cruiseline, image_url_medium, image_url_fallback },
    balcony_price,
    deal_scores,
    special_offers_count,
    urlname,
    odysseus_id,
    start_date,
    end_date,
  } = sailing;
  const discount = Math.abs(
    Math.floor(parseFloat(deal_scores.balcony_score, 10) * 100)
  );

  const link = paths.profile(urlname, odysseus_id).path;
  return (
    <CardContainer key={odysseus_id}>
      <DealCard
        imageUrl={image_url_medium}
        imageUrlFallback={image_url_fallback}
        specialOffersCount={special_offers_count}
        balconyPrice={balcony_price}
        name={display_name}
        cruiselineLogoUrl={cruiseline.logo_url}
        startLocation={departure_port.name}
        endLocation={arrival_port.name}
        discount={discount}
        link={link}
        cardWidth={cardWidth}
        startDate={start_date}
        endDate={end_date}
        onOfferClick={handleOfferClick}
      />
    </CardContainer>
  );
};

const CustomLeftArrow = ({ currentSlide, onClick }) => {
  if (currentSlide === 0) {
    return null;
  }
  return (
    <ArrowWrapper $left onClick={onClick}>
      <Chevron fill={black70} height="32px" width="32px" />
    </ArrowWrapper>
  );
};

CustomLeftArrow.propTypes = {
  currentSlide: PropTypes.number,
  onClick: PropTypes.func,
};

const CustomRightArrow = ({ currentSlide, onClick }) => {
  if (currentSlide === 7) {
    return null;
  }
  return (
    <ArrowWrapper $right onClick={onClick}>
      <Chevron fill={black70} height="32px" width="32px" />
    </ArrowWrapper>
  );
};

CustomRightArrow.propTypes = {
  currentSlide: PropTypes.number,
  onClick: PropTypes.func,
};

const TopDeals = ({ sailings }) => {
  const windowWidth = useCurrentWidth();
  const router = useRouter();
  const handleOfferClick = (link) => {
    router.push(link);
  };
  // Won't be exact pixel width due to spacing, but it's a good estimation
  let cardWidth = windowWidth;
  if (windowWidth > BREAKPOINT_LARGE) {
    cardWidth = Math.floor(windowWidth / 4);
  } else if (windowWidth > BREAKPOINT_MEDIUM) {
    cardWidth = Math.floor(windowWidth / 3);
  }
  const sailingsOfEight = sailings.slice(0, 8);
  return (
    <CarouselContainer>
      <Slider
        {...settings}
        prevArrow={<CustomLeftArrow />}
        nextArrow={<CustomRightArrow />}
      >
        {sailingsOfEight.map((sailing) =>
          renderDealCard(sailing, cardWidth, handleOfferClick)
        )}
      </Slider>
    </CarouselContainer>
  );
};

TopDeals.propTypes = {
  sailings: PropTypes.arrayOf(SAILING_DEAL_SHAPE),
};

export default TopDeals;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { green60, green03 } from "styles/colors";
import fontSizes from "styles/fontSizes";

const IconWrapper = styled.span`
  padding-right: 0.38rem;
  > svg {
    width: 8px;
    height: 8px;
    transform: scale(1.7);
  }
`;

const Container = styled.div`
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    `
    background-color: ${$backgroundColor};
  `}
  ${({ $fontColor }) =>
    $fontColor &&
    `
    color: ${$fontColor};
  `}
  ${({ $icon, $fontColor }) =>
    $icon &&
    $fontColor &&
    ` > ${IconWrapper} {
        > svg {
          fill: ${$fontColor};
        }
    }
  `}
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  ${({ $small }) => ($small ? fontSizes[0] : fontSizes[1])};
  width: max-content;
`;

const InfoSymbol = styled.span`
  margin-left: 6px;
  display: inline-block;
  vertical-align: baseline;
`;

const Label = ({
  backgroundColor = green03,
  color = green60,
  icon,
  children,
  label,
  small,
  showInfoSymbol,
  ...props
}) => {
  return (
    <Container
      $backgroundColor={backgroundColor}
      $fontColor={color}
      $icon={icon}
      $small={small}
      {...props}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
      {showInfoSymbol && <InfoSymbol>&#9432;</InfoSymbol>}
    </Container>
  );
};

Label.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
  label: PropTypes.string,
  small: PropTypes.bool,
  showInfoSymbol: PropTypes.bool,
};

export default Label;

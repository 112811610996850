import React, { useState, useEffect, createRef } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import dynamic from "next/dynamic";
import Link from "next/link";
import { white00, black70, gray50 } from "styles/colors";
import spacing from "styles/spacing";
import fontSizes from "styles/fontSizes";
import media from "styles/media";
import CalendarIcon from "public/icons/calendar.svg";
import LocationIcon from "public/icons/location.svg";
import Label from "components/Label";
import Button from "components/Button";
import Image from "components/Image";

const CardContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.18);
  width: 278px;
  height: 342px;
  display: flex;
  flex-direction: column;
  background-color: ${white00};
  ${media.medium`
    width: 310px;
    height: 365px;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

const CardImage = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 140px;
  ${media.medium`
    height: 150px;
    border-bottom: 1px solid #cccccc;
  `}
  border-radius: 4px 4px 0 0;
`;

const Price = styled.div`
  ${fontSizes[3]}
  font-weight: bold;
  ${media.medium`
    ${fontSizes[4]}
  `}
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing[4]};
  align-items: ${(props) => props.align};
`;

const OfferContainer = styled.div`
  max-width: 100%;
  max-height: 58px;
  margin-top: ${spacing[4]};
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 10px;

  .special-offers-count:hover {
    cursor: pointer;
  }
`;

const SailingName = styled.div`
  cursor: pointer;
  :hover {
    color: ${gray50};
  }
  font-weight: bold;
  text-align: left;
  ${fontSizes[2]}
  ${media.medium`
    ${fontSizes[3]}
  `}
`;

// This container is needed because a placeholder is needed so
// that the sailing name is line-wrapped correctly while the image loads
const CruiselineLogoContainer = styled.div`
  justify-self: end;
  width: 59px;
  height: 15px;
  ${media.medium`
    width: 83px;
    height: 21px;
    margin-top: 0;
  `}
`;

const CruiselineLogo = styled.img`
  width: 59px;
  height: 15px;
  ${media.medium`
    width: 83px;
    height: 21px;
  `}
`;

const ItineraryText = styled.div`
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  align-items: center;
  ${fontSizes[0]}
  ${media.medium`
    white-space: ${(props) =>
      props.$shouldTruncateItinerary ? "nowrap" : "normal"};
    overflow: ${(props) =>
      props.$shouldTruncateItinerary ? "hidden" : "visible"};;
    text-overflow: ellipsis;
  `}
`;

const DiscountText = styled.div`
  color: ${gray50};
  ${fontSizes[0]};
  text-align: left;
`;

const InfoContainer = styled.div`
  padding: ${spacing[4]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  width: auto;
`;

const ViewDealButton = styled(Button)`
  align-self: flex-end;
`;

const IconSpan = styled.span`
  margin-right: 8px;
  display: flex;
  svg {
    fill: ${black70};
  }
`;
const Anchor = styled(Link)`
  text-decoration: none;
  color: inherit;
  height: max-content;
`;

const useComponentHeight = (ref, cardWidth) => {
  const [height, setHeight] = useState(32);
  useEffect(() => {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { height: componentHeight } = boundingRect;
      if (height !== Math.round(componentHeight)) {
        setHeight(Math.round(componentHeight));
      }
    }
  }, [cardWidth, ref]);
  return height;
};

const DealCard = ({
  balconyPrice,
  cruiselineLogoUrl,
  discount,
  imageUrl,
  imageUrlFallback,
  name,
  specialOffersCount,
  startLocation,
  link,
  cardWidth,
  startDate,
  endDate,
  onOfferClick,
}) => {
  const Tooltip = dynamic(() => import("components/Tooltip"), { ssr: false });
  const DiscountTooltip = styled(Tooltip)`
    min-width: 300px;
    width: 90vw !important;
    max-width: 600px;
    ${media.medium`
      min-width: none;
      width: inherit;
      max-width: none;
  `}
  `;
  const nameRef = createRef();
  const nameHeight = useComponentHeight(nameRef, cardWidth);

  const formatDate = (date) => {
    return moment(date, moment.ISO_8601).format("MMM D, YYYY");
  };
  return (
    <CardContainer>
      <Anchor href={link} target="_blank">
        <CardImage
          small={imageUrl}
          src={imageUrlFallback}
          width={280}
          height={210}
        />
      </Anchor>
      <InfoContainer>
        <div>
          <Row>
            <Anchor href={link} target="_blank">
              <SailingName ref={nameRef}>{name}</SailingName>
            </Anchor>
            <CruiselineLogoContainer>
              <CruiselineLogo src={cruiselineLogoUrl} />
            </CruiselineLogoContainer>
          </Row>
          <ItineraryText $shouldTruncateItinerary={nameHeight > 32}>
            <IconSpan>
              <LocationIcon width="14px" height="14px" />
            </IconSpan>
            {startLocation}
          </ItineraryText>
          <ItineraryText $shouldTruncateItinerary={nameHeight > 32}>
            <IconSpan>
              <CalendarIcon width="14px" height="14px" />
            </IconSpan>
            {formatDate(startDate)} → {formatDate(endDate)}
          </ItineraryText>
          {nameHeight < 66 && (
            <OfferContainer>
              <Label
                className="special-offers-count"
                small
                onClick={() => onOfferClick(link)}
              >
                <span className="inline-block text-nowrap">
                  {specialOffersCount} bonus offers available
                </span>
              </Label>
            </OfferContainer>
          )}
        </div>
        <Row align="end">
          <PriceContainer>
            {/* Add extra line if sailing name is only 1 line to fix spacing issue */}
            {nameHeight < 32 && (
              <SailingName>
                <br />
              </SailingName>
            )}
            <Price>Balcony ${balconyPrice}</Price>
            {discount && (
              <DiscountText data-tip data-for="discount-info">
                {discount}% less than usual
              </DiscountText>
            )}
          </PriceContainer>
          <Anchor href={link} target="_blank">
            <ViewDealButton isDiscountText2Lines={cardWidth < 384} small>
              View Deal
            </ViewDealButton>
          </Anchor>
        </Row>
      </InfoContainer>
      <DiscountTooltip id="discount-info" place="top">
        We look at the itinerary, ship class, cruise length and time of year to
        identify the best value options. The cheapest sailing may not always be
        the best value. We control for factors like hurricane season to ensure
        we&apos;re highlighting true value for the money.
      </DiscountTooltip>
    </CardContainer>
  );
};

DealCard.propTypes = {
  balconyPrice: PropTypes.number,
  cardWidth: PropTypes.number,
  cruiselineLogoUrl: PropTypes.string,
  discount: PropTypes.number,
  imageUrl: PropTypes.string,
  imageUrlFallback: PropTypes.string,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  specialOffersCount: PropTypes.number,
  startLocation: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onOfferClick: PropTypes.func.isRequired,
};

export default DealCard;
